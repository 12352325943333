import useHongKongFilingList from "@/hooks/useHongKongFilingList";
import useJapanFilingList from "@/hooks/useJapanFilingList";
import useShangHaiFilingList from "@/hooks/useShangHaiFilingList";
import useShenZhenFilingList from "@/hooks/useShenZhenFilingList";
import useUSAFilingList from "@/hooks/useUSAFilingList";

import useHanoiFilingList from "@/hooks/useHanoiFilingLits";
import useHochiminhFilingList from "@/hooks/useHochiminhFilingList";
import useToggleStore from "@/stores/toggleStore";
import { COUNTRY_CODE } from "@/types/COUNTRY_CODE";
import { EXCHANGE_CODE } from "@/types/EXCHANGE";

const useFilingList = (
  countryCode: COUNTRY_CODE,
  exchange: EXCHANGE_CODE,
  secCode: string
) => {
  const isIncludeInsider = useToggleStore((state) => state.isToggled);
  let isLoading = false;
  let isError = false;
  let isReloading = false;

  if (countryCode === COUNTRY_CODE.USA) {
    const res = useUSAFilingList(exchange, isIncludeInsider, secCode);
    isLoading = res.isLoading;
    isError = res.isError;
    isReloading = res.isReloading;
  }

  if (countryCode === COUNTRY_CODE.HKG) {
    const res = useHongKongFilingList(exchange, secCode);
    isLoading = res.isLoading;
    isError = res.isError;
    isReloading = res.isReloading;
  }

  if (countryCode === COUNTRY_CODE.CHN) {
    if (exchange === EXCHANGE_CODE.SHC) {
      const res = useShangHaiFilingList(exchange, secCode);
      isLoading = res.isLoading;
      isError = res.isError;
      isReloading = res.isReloading;
    }

    if (exchange === EXCHANGE_CODE.SHZ) {
      const res = useShenZhenFilingList(exchange, secCode);
      isLoading = res.isLoading;
      isError = res.isError;
      isReloading = res.isReloading;
    }
  }

  if (countryCode === COUNTRY_CODE.JPN) {
    const res = useJapanFilingList(exchange, secCode);
    isLoading = res.isLoading;
    isError = res.isError;
    isReloading = res.isReloading;
  }

  if (countryCode === COUNTRY_CODE.VTN) {
    if (exchange === EXCHANGE_CODE.HAN) {
      const res = useHanoiFilingList(exchange, secCode);
      isLoading = res.isLoading;
      isError = res.isError;
      isReloading = res.isReloading;
    }
    if (exchange === EXCHANGE_CODE.HCM) {
      const res = useHochiminhFilingList(exchange, secCode);
      isLoading = res.isLoading;
      isError = res.isError;
      isReloading = res.isReloading;
    }
  }

  return { isLoading, isError, isReloading };
};

export default useFilingList;
