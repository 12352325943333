import { GetSecurityName } from "@/types/model/response/SecurityNameType";
import getSecurityName from "@/utils/getSecurityName";
import { useQuery } from "@tanstack/react-query";
import _ from "lodash";

const useSecurityName = (selectedCountry: string, searchParam: string) => {
  return useQuery<GetSecurityName[]>(
    ["securityName", selectedCountry, searchParam],
    () => getSecurityName(selectedCountry, searchParam.toUpperCase()),
    {
      select: (data: GetSecurityName[]) => {
        const symbolMatchList: GetSecurityName[] = [];
        const symbolUnmatchedList: GetSecurityName[] = [];

        data.sort((a: GetSecurityName, b: GetSecurityName) =>
          a.securityCode.localeCompare(b.securityCode)
        );

        _.sortedUniqBy(data, (v) => v.securityCode).forEach((v) =>
          v.securityCode?.indexOf(searchParam.toUpperCase() ?? "") === 0
            ? symbolMatchList.push(v)
            : symbolUnmatchedList.push(v)
        );

        return [...symbolMatchList, ...symbolUnmatchedList];
      },
      enabled: searchParam.length > 0,
      staleTime: 1000 * 60,
      networkMode: "always",
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
};

export default useSecurityName;
