import styled from "@emotion/styled";

import * as Text from "@/components/atoms/common/Text";
import useSearchStore from "@/stores/searchStore";
import { EXCHANGE } from "@/types/EXCHANGE";
import { useLocation } from "react-router-dom";

interface HashTagProps {
  hashTagItem: string;
  exchange: string;
}

const HashTagContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.375rem;
  max-width: 15.4rem;
`;

const HashTagText = styled(Text.Spoqa400)<{
  itemLength: number;
  selectedCountry: string;
}>`
  font-size: 1.12rem;

  text-overflow: ellipsis;
  overflow: hidden;

  display: -webkit-box;
  word-break: break-all;
  word-wrap: break-word;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  color: ${({ theme }) => theme.colors.textColor08};

  height: 1.875rem;

  padding: 0.1875rem 0.375rem;

  background-color: ${({ theme }) => theme.colors.object02};

  ${({ selectedCountry }) =>
    (selectedCountry === "미국" || selectedCountry === EXCHANGE.USA) &&
    `&:first-of-type {
    font-weight: 700;
  }`}
`;

const HASH_ITEM_LENGTH = 2;

const HashTag: React.FC<HashTagProps> = ({ hashTagItem, exchange }) => {
  const hashTagItems = hashTagItem
    ?.split(", ")
    .filter((item) => item.length < 10)
    .slice(0, HASH_ITEM_LENGTH);

  const selectedCountry = useSearchStore((state) => state.selectedCountry);

  return (
    <HashTagContainer>
      {hashTagItems?.map(
        (item, idx) =>
          item && (
            <HashTagText
              key={idx}
              itemLength={HASH_ITEM_LENGTH}
              selectedCountry={
                useLocation().pathname === "/list" ? exchange : selectedCountry
              }
            >
              {(selectedCountry === "미국" || selectedCountry === EXCHANGE.USA) &&
              idx === 0
                ? item
                : `#${item}`}
            </HashTagText>
          )
      )}
    </HashTagContainer>
  );
};

export default HashTag;
