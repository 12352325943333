import styled from "@emotion/styled";

import * as Text from "@/components/atoms/common/Text";

interface TimeStampProps {
  time: string;
}

const TimeStampText = styled(Text.Spoqa400)`
  font-size: 1.125rem;
  line-height: 1.6875rem;

  color: ${({ theme }) => theme.colors.textColor03};
`;

const TimeStamp = ({ time }: TimeStampProps) => {
  return <TimeStampText>{time}</TimeStampText>;
};

export default TimeStamp;
