import styled from "@emotion/styled";
import React from "react";

const IconContainer = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 480px) {
    scale: calc(480 / 540);
  }

  @media (max-width: 430px) {
    scale: calc(430 / 540);
  }

  @media (max-width: 428px) {
    scale: calc(428 / 540);
  }

  @media (max-width: 414px) {
    scale: calc(414 / 540);
  }

  @media (max-width: 390px) {
    scale: calc(390 / 540);
  }

  @media (max-width: 375px) {
    scale: calc(375 / 540);
  }

  @media (max-width: 360px) {
    scale: calc(360 / 540);
  }

  @media (max-width: 320px) {
    scale: calc(320 / 540);
  }
`;

type XIconProps = {
  onClickHandler: () => void;
};

const XIcon: React.FC<XIconProps> = ({ onClickHandler }) => {
  return (
    <IconContainer onClick={onClickHandler}>
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_232_5314)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.0005 27.4998C21.904 27.4998 27.5005 21.9033 27.5005 14.9998C27.5005 8.0962 21.904 2.49976 15.0005 2.49976C8.09693 2.49976 2.50049 8.0962 2.50049 14.9998C2.50049 21.9033 8.09693 27.4998 15.0005 27.4998ZM9.83606 18.7498L13.586 14.9999L9.83603 11.2499L11.2502 9.83565L15.0002 13.5856L18.7503 9.83563L20.1645 11.2498L16.4145 14.9999L20.1644 18.7499L18.7502 20.1641L15.0002 16.4141L11.2503 20.164L9.83606 18.7498Z"
            fill="#CBCDCF"
          />
        </g>
        <defs>
          <clipPath id="clip0_232_5314">
            <rect width="30" height="30" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </IconContainer>
  );
};

export default XIcon;
