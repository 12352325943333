import { AnalysisDEF14A } from "@/types/model/response/USAFilingType";

export type ParsedFrom14Data = {
  no: number;
  text: string;
};

const semiPostfixRegex = /(;)$/;

const parseFormType14 = (rawData: AnalysisDEF14A): ParsedFrom14Data[] =>
  rawData.proposalKorList.map((proposal, index) => ({
    no: index + 1,
    text: (proposal || "").replace(semiPostfixRegex, "."),
  }));

export default parseFormType14;
