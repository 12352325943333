import { EXCHANGE } from "@/types/EXCHANGE";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
dayjs.extend(timezone);

type LocaleType =
  | "Asia/Seoul"
  | "America/New_York"
  | "Asia/Shanghai"
  | "Asia/Ho_Chi_Minh";

type GetTime = (
  time: string,
  local: LocaleType
) => {
  day: string;
  hour: string;
};

export const getTime: GetTime = (time, local) => {
  const day = dayjs(time).tz(local).format("YYYY.MM.DD");

  const hour = dayjs(time).tz(local).format("HH:mm:ss");
  return {
    day,
    hour,
  };
};

type GetLocaleTime = (
  time: string,
  exchange?: EXCHANGE
) => {
  day: string;
  hour: string;
};

export const getLocaleTime: GetLocaleTime = (time, exchange) => {
  switch (exchange) {
    case EXCHANGE.USA:
      return getTime(time, "America/New_York");
    case EXCHANGE.HONGKONG:
    case EXCHANGE.SHANGHAI:
    case EXCHANGE.SHENZHEN:
      return getTime(time, "Asia/Shanghai");
    case EXCHANGE.HANOI:
    case EXCHANGE.HOCHIMINH:
      return getTime(time, "Asia/Ho_Chi_Minh");
    default:
      return getTime(time, "Asia/Seoul");
  }
};
