import SearchIcon from "@/components/atoms/search/SearchIcon";
import XIcon from "@/components/atoms/search/XIcon";
import styled from "@emotion/styled";

const SEARCH_BAR_PLACEHOLDER = "종목명/코드 검색";

const SearchBarContainer = styled.div`
  padding: 0.75rem 1.875rem;

  height: 5.625rem;
`;

const SearchInputContainer = styled.div`
  display: flex;
  align-items: center;

  height: 100%;
  width: 100%;

  gap: 0.5rem;

  padding: 0 1.125rem;

  background-color: ${({ theme }) => theme.colors.object02};
  border-radius: 1.125rem;
`;

const SearchInput = styled.input`
  width: 100%;
  height: fit-content;

  font-family: "Spoqa Han Sans Neo", "sans-serif";
  font-size: 1.40625rem;
  font-weight: 500;
  line-height: 1;

  vertical-align: middle;

  background-color: ${({ theme }) => theme.colors.object02};
  border-radius: 1.125rem;
  border: none;
  outline: none;

  color: ${({ theme }) => theme.colors.object09};

  ::placeholder {
    color: ${({ theme }) => theme.colors.object05};
  }
`;

interface SearchBarProps {
  currentItem: string;
  searchKeyword: string;
  handleSearchKeyword: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleOnClickXIcon: () => void;
}

const SearchBar: React.FC<SearchBarProps> = ({
  currentItem,
  searchKeyword,
  handleOnClickXIcon,
  handleSearchKeyword,
}) => {
  return (
    <SearchBarContainer>
      <SearchInputContainer>
        <SearchIcon />
        <SearchInput
          placeholder={SEARCH_BAR_PLACEHOLDER}
          value={searchKeyword}
          onChange={handleSearchKeyword}
        />
        {(currentItem || searchKeyword) && (
          <XIcon onClickHandler={handleOnClickXIcon} />
        )}
      </SearchInputContainer>
    </SearchBarContainer>
  );
};

export default SearchBar;
