export enum EXCHANGE {
  USA = "USA",
  HONGKONG = "HONGKONG",
  SHANGHAI = "SHANGHAI",
  SHENZHEN = "SHENZHEN",
  JAPAN = "JAPAN",
  HANOI = "HANOI",
  HOCHIMINH = "HOCHIMINH",
}

export enum EXCHANGE_CODE {
  SHZ = "SZS", // 심천
  SHC = "SHS", // 상하이
  HKG = "HKS", // 홍콩
  TYO = "TSE", // 도쿄
  NYY = "NYS", // 뉴욕
  AMS = "AMS", // 뉴욕 AMEX
  NQQ = "NAS", // 나스닥
  HCM = "HSX", // 호치민
  HAN = "HNX", // 하노이
}
