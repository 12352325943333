import styled from "@emotion/styled";

import Tag from "@/components/atoms/list/Tag";
import HashTag from "@/components/atoms/list/HashTag";

import { useFilingsStore } from "@/stores/filingsStore";
import { MODEL_VIEW_FILINGS_ITEM } from "@/types/model/view/ParsedFilingType";

interface FilingItemHeaderProps {
  item: MODEL_VIEW_FILINGS_ITEM;
}

const FilingItemHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 0.6094rem;
`;

const FilingItemHeader: React.FC<FilingItemHeaderProps> = ({ item }) => {
  const hashTagItem = item?.topic;

  return (
    <FilingItemHeaderContainer>
      {item.form !== "" && <Tag>{item.form}</Tag>}
      <HashTag exchange={item.exchange} hashTagItem={hashTagItem} />
    </FilingItemHeaderContainer>
  );
};

export default FilingItemHeader;
