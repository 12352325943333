import styled from "@emotion/styled";

import * as Text from "@/components/atoms/common/Text";

export const TagText = styled(Text.Spoqa700)`
  font-size: 1.125rem;
  line-height: 1.6875rem;

  color: ${({ theme }) => theme.colors.black70};
`;
