import * as Text from "@/components/atoms/common/Text";
import WarningIcon from "@/components/atoms/common/WarringIcon";
import ErrorPage from "@/components/atoms/list/Error";
import SearchBar from "@/components/molecules/search/SearchBar";
import SearchCountry from "@/components/molecules/search/SearchCountry";
import SearchListItem from "@/components/molecules/search/SearchListItem";
import Loader from "@/components/organisms/common/Loading";
import FilingItem from "@/components/organisms/list/FilingItem";
import ToggleArea from "@/components/organisms/list/ToggleArea";
import useFilingList from "@/hooks/useFilingList";
import useSecurityName from "@/hooks/useSecurityName";
import { useFilingsStore } from "@/stores/filingsStore";
import useScrollStore from "@/stores/scrollStore";
import useSearchStore from "@/stores/searchStore";
import { COUNTRY_CODE } from "@/types/COUNTRY_CODE";
import { EXCHANGE, EXCHANGE_CODE } from "@/types/EXCHANGE";
import { logEvent } from "@/utils/logEvent";
import styled from "@emotion/styled";
import { useEffect, useState } from "react";

const EMPTY_SEARCH_WARNING = "조회된 종목이 없습니다.";
const getCountryEnum = (country: string): COUNTRY_CODE => {
  switch (country) {
    case "미국":
      return COUNTRY_CODE.USA;
    case "중국(상해)":
    case "중국(심천)":
      return COUNTRY_CODE.CHN;
    case "홍콩":
      return COUNTRY_CODE.HKG;
    case "일본":
      return COUNTRY_CODE.JPN;
    case "베트남(호치민)":
    case "베트남(하노이)":
      return COUNTRY_CODE.VTN;
    default:
      return COUNTRY_CODE.USA;
  }
};

const getExchangeEnum = (country: string): EXCHANGE_CODE => {
  switch (country) {
    case "미국":
      return EXCHANGE_CODE.NQQ;
    case "중국(상해)":
      return EXCHANGE_CODE.SHC;
    case "중국(심천)":
      return EXCHANGE_CODE.SHZ;
    case "홍콩":
      return EXCHANGE_CODE.HKG;
    case "일본":
      return EXCHANGE_CODE.TYO;
    case "베트남(하노이)":
      return EXCHANGE_CODE.HAN;
    case "베트남(호치민)":
      return EXCHANGE_CODE.HCM;
    default:
      return EXCHANGE_CODE.NQQ;
  }
};

const getCountryEngName = (country: string) => {
  switch (country) {
    case "미국":
      return EXCHANGE.USA;
    case "중국(상해)":
      return EXCHANGE.SHANGHAI;
    case "중국(심천)":
      return EXCHANGE.SHENZHEN;
    case "홍콩":
      return EXCHANGE.HONGKONG;
    case "일본":
      return EXCHANGE.JAPAN;
    case "베트남(하노이)":
      return EXCHANGE.HANOI;
    case "베트남(호치민)":
      return EXCHANGE.HOCHIMINH;
    default:
      return EXCHANGE.USA;
  }
};

const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SearchListContainer = styled.div`
  padding: 0 1.875rem;
`;

const MainContainer = styled.main<{ countryCode: string }>`
  display: flex;
  flex-direction: column;

  margin-bottom: 4.5rem;

  gap: 0.75rem;
`;

const FilingItemWrapper = styled.div``;

const WarningContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;

  margin-top: 25vh;

  @media (min-width: 717px) {
    margin-top: 10vh;
  }
`;

const EmptySearchWarningContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-top: 25vh;

  @media (min-width: 717px) {
    margin-top: 10vh;
    gap: 1.5rem;
  }
`;

const WarningText = styled(Text.Spoqa400)`
  font-size: 1.40625rem;
  color: ${({ theme }) => theme.colors.object05};
`;

const Search = () => {
  const searchKeyword = useSearchStore((state) => state.searchKeyword);
  const setSearchKeyword = useSearchStore((state) => state.setSearchKeyword);
  const selectedCountry = useSearchStore((state) => state.selectedCountry);
  const setSelectedCountry = useSearchStore(
    (state) => state.setSelectedCountry
  );
  const currentItem = useSearchStore((state) => state.currentItem);
  const setCurrentItem = useSearchStore((state) => state.setCurrentItem);

  const [isSearch, setIsSearch] = useState<boolean>(false);

  const handleSearchKeyword = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!isSearch) {
      setIsSearch(true);
    }

    setSearchKeyword(e.target.value);
  };

  const handleSearchCountry = (country: string) => {
    setSelectedCountry(country);
    setSearchKeyword("");
    setCurrentItem("");
    setIsSearch(false);
  };

  const handleOnClickSearchItem = (securityCode: string) => {
    setCurrentItem(securityCode);
    setSearchKeyword(securityCode);
    setIsSearch(false);
    logEvent("mts search event", {
      secCode: securityCode,
      exchange: getCountryEnum(selectedCountry),
    });
  };

  const handleOnClickXIcon = () => {
    setCurrentItem("");
    setSearchKeyword("");
    setIsSearch(false);
  };

  const { data } = useSecurityName(
    getCountryEngName(selectedCountry),
    searchKeyword
  );

  const { isLoading, isError, isReloading } = useFilingList(
    getCountryEnum(selectedCountry),
    getExchangeEnum(selectedCountry) as EXCHANGE_CODE,
    currentItem
  );

  const filings = useFilingsStore((state) => state.filings);
  const scroll = useScrollStore((state) => state.scroll);

  useEffect(() => {
    logEvent("mts enter search page");
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      window.scrollTo(0, scroll);
    }, 5);
    return () => clearTimeout(timer);
  }, [scroll]);

  if (isError) return <ErrorPage />;

  return (
    <SearchContainer>
      <SearchBar
        currentItem={currentItem}
        searchKeyword={searchKeyword}
        handleOnClickXIcon={handleOnClickXIcon}
        handleSearchKeyword={handleSearchKeyword}
      />
      <SearchCountry
        selectedCountry={selectedCountry}
        handleSearchCountry={handleSearchCountry}
      />
      {isLoading || isReloading ? (
        <Loader />
      ) : isSearch ? (
        <SearchListContainer>
          {data?.length !== 0 ? (
            data?.map((item, index) => (
              <SearchListItem
                key={`${item.securityCode}_${index}`}
                item={item}
                index={index}
                handleOnClickSearchItem={handleOnClickSearchItem}
              />
            ))
          ) : (
            <EmptySearchWarningContainer>
              <WarningIcon />
              <WarningText>{EMPTY_SEARCH_WARNING}</WarningText>
            </EmptySearchWarningContainer>
          )}
        </SearchListContainer>
      ) : (
        <MainContainer countryCode={getCountryEnum(selectedCountry)}>
          {getCountryEnum(selectedCountry) === COUNTRY_CODE.USA && (
            <ToggleArea />
          )}
          <FilingItemWrapper>
            {filings &&
              filings.map(
                (filingItem, idx) =>
                  filingItem.summaryTiny &&
                  filingItem.korName && (
                    <FilingItem
                      key={`${filingItem.id}_${idx}`}
                      item={filingItem}
                    />
                  )
              )}
            {filings.length === 0 && (
              <WarningContainer>
                <WarningIcon />
                <WarningText>정보가 없습니다.</WarningText>
              </WarningContainer>
            )}
          </FilingItemWrapper>
        </MainContainer>
      )}
    </SearchContainer>
  );
};

export default Search;
