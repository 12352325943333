import { GetSecurityName } from "@/types/model/response/SecurityNameType";
import styled from "@emotion/styled";
import * as Text from "@/components/atoms/common/Text";

const SearchListItemContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  height: 4.5rem;

  border-bottom: 1px solid ${({ theme }) => theme.colors.object03};
`;

const SearchListItemTickerText = styled(Text.Spoqa700)`
  font-size: 1.125rem;

  color: ${({ theme }) => theme.colors.textColor08};
`;

const SearchListItemNameText = styled(Text.Spoqa700)`
  font-size: 1.3125rem;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  color: ${({ theme }) => theme.colors.textColor06};
`;

interface SearchListItemProps {
  item: GetSecurityName;
  index: number;
  handleOnClickSearchItem: (securityCode: string) => void;
}

const SearchListItem: React.FC<SearchListItemProps> = ({
  item,
  index,
  handleOnClickSearchItem,
}) => {
  return (
    <SearchListItemContainer
      onClick={() => handleOnClickSearchItem(item.securityCode)}
    >
      <SearchListItemTickerText>{item.securityCode}</SearchListItemTickerText>
      <SearchListItemNameText>{item.securityKorName}</SearchListItemNameText>
    </SearchListItemContainer>
  );
};

export default SearchListItem;
