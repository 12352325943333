import styled from "@emotion/styled";

const SEARCH_COUNTRY = [
  "미국",
  "중국(상해)",
  "중국(심천)",
  "홍콩",
  "일본",
  "베트남(호치민)",
  "베트남(하노이)",
];

const SearchCountryContainer = styled.div`
  display: flex;
  height: 4.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.object03};
  padding: 0 1.875rem;

  gap: 1.5rem;

  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`;

type SearchCountryItemContainer = {
  isSelected: boolean;
};

const SearchCountryItemContainer = styled.div<SearchCountryItemContainer>`
  display: flex;
  align-items: center;
  height: 100%;
  min-width: fit-content;

  font-family: "Spoqa Han Sans Neo", "sans-serif";
  font-size: 1.40625rem;

  ${({ isSelected, theme }) =>
    isSelected
      ? `
    color: ${theme.colors.primary};
    border-bottom: 2px solid #000;
    font-weight: 500;
  `
      : `
    color: ${theme.colors.textColor04};
    font-weight: 400;
    border-bottom: 2px solid transparent;
  `}
`;

interface SearchCountryProps {
  selectedCountry: string;
  handleSearchCountry: (country: string) => void;
}

const SearchCountry: React.FC<SearchCountryProps> = ({
  selectedCountry,
  handleSearchCountry,
}) => {
  return (
    <SearchCountryContainer>
      {SEARCH_COUNTRY.map((country, index) => (
        <SearchCountryItemContainer
          key={`${country}_${index}`}
          isSelected={country === selectedCountry}
          onClick={() => handleSearchCountry(country)}
        >
          {country}
        </SearchCountryItemContainer>
      ))}
    </SearchCountryContainer>
  );
};

export default SearchCountry;
