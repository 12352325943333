import dayjs from "dayjs";
import { gql } from "graphql-request";
import { useQuery } from "@tanstack/react-query";

import { useBaseDate, useFilingsStore } from "@/stores/filingsStore";

import createGraphqlClient from "@/utils/createGraphqlClient";
import { T_RESPONSE_HOCHIMINH_FILINGS } from "@/types/model/response/HochiminhFilingType";
import parseHochiminhFiling from "@/utils/parser/parseHochiminhFiling";

const query = (baseDate: string, secCode?: string) => gql`
  query HOCHIMINH {
    getDisclosure(
      filter: {
        exchangeType: HOCHIMINH
        ${secCode ? `securityCode: "${secCode}"` : ""}
        limit: 100
        page: 1
        timeRange: { endDate: "${baseDate}", rangeLength: 1, rangeLengthUnit: Y }
      }
    ) {
      id
      filingDate
      korName
      details {
        ... on Hochiminh {
          secCode
          fileLink
        }
      }
      analysisDetails {
        summarizeTinyKor
        summarizeLongKor
        topicKor
      }
    }
  }
`;

const useHochiminhFilingList = (exchange: string, secCode?: string) => {
  const baseDate = useBaseDate((state) =>
    dayjs(state.baseDate).format("YYYY-MM-DD")
  );

  const setFilings = useFilingsStore((state) => state.setFilings);

  const client = createGraphqlClient();

  const { isLoading, isError, isFetching, isSuccess } = useQuery(
    [exchange, secCode, baseDate],
    () =>
      client.request<T_RESPONSE_HOCHIMINH_FILINGS>(query(baseDate, secCode)),
    {
      enabled: true,
      refetchOnWindowFocus: false,
      select: (data) => {
        return parseHochiminhFiling(data);
      },
      networkMode: "always",
      // staleTime: 5 * 60 * 1000,
      onSuccess(data) {
        setFilings(data);
      },
    }
  );

  const isReloading = isFetching && isSuccess;

  return { isLoading, isError, isReloading };
};

export default useHochiminhFilingList;
