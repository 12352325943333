import styled from "@emotion/styled";

const IconContainer = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  transform-origin: center;

  @media (max-width: 480px) {
    scale: calc(480 / 540);
  }

  @media (max-width: 430px) {
    scale: calc(430 / 540);
  }

  @media (max-width: 428px) {
    scale: calc(428 / 540);
  }

  @media (max-width: 414px) {
    scale: calc(414 / 540);
  }

  @media (max-width: 390px) {
    scale: calc(390 / 540);
  }

  @media (max-width: 375px) {
    scale: calc(375 / 540);
  }

  @media (max-width: 360px) {
    scale: calc(360 / 540);
  }

  @media (max-width: 320px) {
    scale: calc(320 / 540);
  }
`;

const SearchIcon = () => {
  return (
    <IconContainer>
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_231_5255)">
          <ellipse
            cx="12.375"
            cy="12.375"
            rx="8.375"
            ry="8.375"
            stroke="#A4A6AA"
            strokeWidth="2.18182"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M18.3748 18.3748L23.8748 23.8748"
            stroke="#A4A6AA"
            strokeWidth="2.18182"
            strokeLinecap="square"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_231_5255">
            <rect
              width="23.75"
              height="23.75"
              fill="white"
              transform="translate(2.5 2.5)"
            />
          </clipPath>
        </defs>
      </svg>
    </IconContainer>
  );
};

export default SearchIcon;
