interface T_EVENT_LABEL_MAPPING_TABLE {
  [key: string]: string;
}

export const EVENT_LABEL_MAPPING_TABLE: T_EVENT_LABEL_MAPPING_TABLE = {
  dividend: "배당",
  buyback: "자사주 매입",
  ticker_change: "종목 코드 변경",
  trading_halted: "거래정지",
  delisting: "상장폐지",
  lawsuit: "소송",
  governance_reform: "지배구조 변경",
  mergers_and_acquisitions: "인수 및 합병",
  reverse_split: "주식병합",
  split: "주식분할",
};
