import styled from "@emotion/styled";

import FilingItemBody from "@/components/molecules/list/FilingItemBody";
import FilingItemFooter from "@/components/molecules/list/FilingItemFooter";
import FilingItemHeader from "@/components/molecules/list/FilingItemHeader";
import { MODEL_VIEW_FILINGS_ITEM } from "@/types/model/view/ParsedFilingType";
import { Link, useLocation } from "react-router-dom";

import * as Text from "@/components/atoms/common/Text";
import useScrollStore from "@/stores/scrollStore";
import { logEvent } from "@/utils/logEvent";
import parseSearchParams from "@/utils/parser/parseSearchParams";
interface FilingItemProps {
  item: MODEL_VIEW_FILINGS_ITEM;
  from?: string;
}

const getKoreanExchange = (exchange: string) => {
  switch (exchange) {
    case "NAS":
      return "나스닥";
    case "NYS":
      return "뉴욕";
    case "AMS":
      return "아멕스";
    case "SHS":
      return "상해";
    case "SZS":
      return "심천";
    case "HKS":
      return "홍콩";
    case "TSE":
      return "도쿄";
    case "HSX":
      return "호치민";
    case "HNX":
      return "하노이";
    default:
      return "";
  }
};

const FilingItemContainer = styled(Link)`
  display: flex;
  flex-direction: column;
  gap: 0.5625rem;

  padding: 0 1.875rem 1.5rem 1.875rem;

  margin-top: 1.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.object03};

  &:last-child {
    border-bottom: none;
  }
`;

const FilingItemInfoContainer = styled.div`
  display: inline-flex;
  align-items: center;
`;

const FilingItemKorName = styled(Text.Spoqa700)`
  font-size: 1.03125rem;
  line-height: 1.6875rem;

  color: ${({ theme }) => theme.colors.object09};
`;

const FilingItemInfoText = styled(Text.Spoqa400)`
  font-size: 1.03125rem;
  line-height: 1.6875rem;
  padding-left: 0.375rem;
  color: ${({ theme }) => theme.colors.textColor04};
`;

const FilingItem: React.FC<FilingItemProps> = ({ item, from }) => {
  const {
    country_code: countryCode,
    exchange,
    sec_code: secCode,
    use_popup: usePopup,
  } = parseSearchParams(useLocation().search);

  const encodeDetailURI = `/detail?id=${encodeURIComponent(
    item.id
  )}&country_code=${countryCode}&exchange=${exchange}&sec_code=${secCode}&use_popup=${usePopup}${from?`&from=${from}`:""}`;

  const setScroll = useScrollStore((state) => state.setScroll);

  const handleOnClick = () => {
    setScroll(window.scrollY);
    logEvent("mts enter detail page", {
      id: item.id,
      countryCode,
      exchange,
      secCode,
      scrollY: window.scrollY,
    });
  };

  return (
    <FilingItemContainer to={encodeDetailURI} onClick={handleOnClick}>
      <FilingItemHeader item={item} />
      {location.pathname === "/search" && (
        <FilingItemInfoContainer>
          <span>
            <FilingItemKorName>{item.korName}</FilingItemKorName>
            <FilingItemInfoText>{item.secCode}</FilingItemInfoText>
            <FilingItemInfoText>
              {getKoreanExchange(item.exchangeLabel)}
            </FilingItemInfoText>
          </span>
        </FilingItemInfoContainer>
      )}
      <FilingItemBody item={item} />
      <FilingItemFooter item={item} />
    </FilingItemContainer>
  );
};

export default FilingItem;
