import styled from "@emotion/styled";

const Circle = styled.div<{ isOn: boolean }>`
  position: absolute;
  width: 2.0625rem;
  height: 2.0625rem;
  border-radius: 50%;
  left: 1px;
  background: ${({ theme }) => theme.colors.white};

  transition: all 0.25s ease-in-out;

  ${({ isOn }) => isOn && "transform: translate(1.48rem, 0);"}
`;

export default Circle;
