import styled from "@emotion/styled";
import { useLocation } from "react-router-dom";

import EmptyPage from "@/components/atoms/list/Empty";
import Loader from "@/components/organisms/common/Loading";
import FilingItem from "@/components/organisms/list/FilingItem";

import useFilingList from "@/hooks/useFilingList";
import { useFilingsStore } from "@/stores/filingsStore";
import parseSearchParams from "@/utils/parser/parseSearchParams";

import ErrorPage from "@/components/atoms/list/Error";
import ToggleArea from "@/components/organisms/list/ToggleArea";
import useScrollStore from "@/stores/scrollStore";
import { COUNTRY_CODE } from "@/types/COUNTRY_CODE";
import { EXCHANGE_CODE } from "@/types/EXCHANGE";
import { logEvent } from "@/utils/logEvent";
import { useEffect } from "react";

const MainContainer = styled.main<{ countryCode: string }>`
  display: flex;
  flex-direction: column;

  margin-bottom: 4.5rem;

  gap: 0.75rem;
`;

const FilingItemWrapper = styled.div`
  /* padding: 0 1.5rem; */
`;

const Main = () => {
  const {
    country_code: countryCode,
    exchange,
    sec_code: secCode,
  } = parseSearchParams(useLocation().search);

  const { isLoading, isError } = useFilingList(
    countryCode as COUNTRY_CODE,
    exchange as EXCHANGE_CODE,
    secCode
  );

  const filings = useFilingsStore((state) => state.filings);
  const scroll = useScrollStore((state) => state.scroll);

  useEffect(() => {
    const timer = setTimeout(() => {
      window.scrollTo(0, scroll);
    }, 5);
    return () => clearTimeout(timer);
  }, [scroll]);

  useEffect(() => {
    logEvent("mts enter list page", {
      countryCode,
      exchange,
      secCode,
    });
  }, []);

  if (isLoading) return <Loader />;
  if (isError) return <ErrorPage />;

  return (
    <MainContainer countryCode={countryCode}>
      {filings.length === 0 ? (
        <EmptyPage />
      ) : (
        <>
          {countryCode === COUNTRY_CODE.USA && <ToggleArea />}
          <FilingItemWrapper>
            {filings.map(
              (filingItem, idx) =>
                filingItem.summaryTiny && (
                  <FilingItem
                    key={`${filingItem.id}_${idx}`}
                    item={filingItem}
                    from="price"
                  />
                )
            )}
          </FilingItemWrapper>
        </>
      )}
    </MainContainer>
  );
};

export default Main;
