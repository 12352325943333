import styled from "@emotion/styled";

const ToggleBack = styled.div<{ isOn: boolean }>`
  position: absolute;
  width: 3.75rem;
  height: 2.25rem;
  border-radius: 1.125rem;
  background: ${({ theme, isOn }) =>
    isOn ? theme.colors.primary : theme.colors.object04};
`;

export default ToggleBack;
