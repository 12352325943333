
declare global {
  interface Window {
    HKWebBridge: {
      openpopup: (nType: number, url: string, aniType: number, data: string | null) => void
    }
  }
}
const nType = 0;
const aniType = 2;
const data = null;
const androidPopupExt = ["xml", "htm", "html", "txt"]

const CommonUtil = {
  isAndroid() {
    return (navigator.userAgent.indexOf('Android') > -1);
  },
  isIOS() {
    return (navigator.userAgent.indexOf('iPhone') > -1) || (navigator.userAgent.indexOf('iPad') > -1);
  },
  isMobile() {
    return CommonUtil.isAndroid() || CommonUtil.isIOS();
  }
};

if (typeof window.HKWebBridge === "undefined") {
  window.HKWebBridge = {
    'openpopup': function () {
      console.info('Android Native API - openpopup');
    },
  }
}

function anchorDownload(url: string) {
  const anchor = document.getElementById("globalDownAnchor") as HTMLAnchorElement | null;
  if (anchor) {
    anchor.href = url;
    anchor.click();
  }
}

function exceptAnchorDownload(url: string, ext: string, osType: string) {

  if(!ext) {
    anchorDownload(url)
    return true;
  }

  if (url.indexOf("www.szse.cn") > -1 && url.indexOf("download") > -1) {
    anchorDownload(url)
    return true;
  }
  if (url.indexOf("www.hsx.vn") > -1 && ext !== "html") {
    anchorDownload(url)
    return true;
  }
  if (osType === "IOS" && url.indexOf("owa.hnx.vn") > -1 && ext === "zip") {
    anchorDownload(url)
    return true;
  }
  return false;
}

const spaceChgRegx = /\s|\%20/g

export const WebviewBridge = {
  openOrgDocs(orgUrl: string, ext: string, popupOpen?: boolean) {
    const type = CommonUtil.isAndroid() ? "AOS" : CommonUtil.isIOS() ? "IOS" : "ETC";
    const _url = type !== "ETC" && orgUrl.indexOf("owa.hnx.vn") > -1 ? orgUrl.trim().replace(spaceChgRegx, "\%2520") : orgUrl;

    if (popupOpen) {
      if (exceptAnchorDownload(_url, ext, type)) {
        return;
      }
      if (CommonUtil.isAndroid() && androidPopupExt.includes(ext.toLocaleLowerCase())) {
        window.HKWebBridge.openpopup(nType, _url, aniType, data);
        return;
      } else if (CommonUtil.isIOS()) {
        const goUrl = 'toapp://openpopup' + '/?/*/' + nType + '/?/*/' + _url + '/?/*/' + aniType + '/?/*/' + data;
        const iFrame = document.createElement('IFRAME');
        iFrame.setAttribute('src', goUrl);
        try {
          document.body.appendChild(iFrame);
          iFrame.parentNode?.removeChild(iFrame);
        } catch (e) {
          console.error("error occured >>", e);
          //add sentry handler
        }
        return;
      }
    }
    anchorDownload(_url)
  },
  isIOS() {
    return CommonUtil.isIOS();
  }
}