import {
  CATEGORY_MAPPING_HANOI,
  CATEGORY_MAPPING_HKEX,
  CATEGORY_MAPPING_JAPAN,
  CATEGORY_MAPPING_SHANGHAI,
  CATEGORY_MAPPING_SHENZHEN,
  CATEGORY_MAPPING_USA,
} from "@/constants/categoryMappingTable";
import { EVENT_LABEL_MAPPING_TABLE } from "@/constants/eventLabelMappingTable";
import type { MODEL_VIEW_FILINGS_ITEM } from "@/types/model/view/ParsedFilingType";

export type MODEL_VIEW_FILINGS = MODEL_VIEW_FILINGS_ITEM[];

const mappingTable: { [key: string]: { kor: string } } = {
  ...CATEGORY_MAPPING_SHENZHEN,
  ...CATEGORY_MAPPING_SHANGHAI,
  ...CATEGORY_MAPPING_HKEX,
  ...CATEGORY_MAPPING_JAPAN,
  ...CATEGORY_MAPPING_USA,
  ...CATEGORY_MAPPING_HANOI,
};

export const getKoreanValue = (key: string): string => {
  // mappingTable의 요소 중 undefined 값이 없는지 확인합니다.
  if (mappingTable[key] !== undefined) {
    return mappingTable[key].kor;
  } else {
    return "기타 공시";
  }
};

export const getKoreanEventLabel = (keys: string[]): string[] => {
  const koreanEventLabel: string[] = [];
  if (keys === null) return [];
  keys.forEach((key) => {
    if (key !== "NULL") koreanEventLabel.push(EVENT_LABEL_MAPPING_TABLE[key]);
  });

  return koreanEventLabel;
};
