import styled from "@emotion/styled";

import * as Text from "@/components/atoms/common/Text";

const EmptyPageContainer = styled.div`
  display: flex;
  justify-content: center;

  width: 100%;

  margin-top: 6.25rem;
`;

const EmptyPageText = styled(Text.Spoqa400)`
  font-size: 1.3125rem;
  line-height: 2rem;

  color: ${({ theme }) => theme.colors.black70};
`;

const EmptyPage = () => {
  return (
    <EmptyPageContainer>
      <EmptyPageText>조회할 공시가 없습니다.</EmptyPageText>
    </EmptyPageContainer>
  );
};

export default EmptyPage;
