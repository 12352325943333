import styled from "@emotion/styled";

const Spoqa700 = styled.span`
  font-family: "Spoqa Han Sans Neo";
  font-weight: 700;
`;

const Spoqa500 = styled.span`
  font-family: "Spoqa Han Sans Neo";
  font-weight: 500;
`;

const Spoqa400 = styled.span`
  font-family: "Spoqa Han Sans Neo";
  font-weight: 400;
`;

export { Spoqa700, Spoqa500, Spoqa400 };
