import { AnalysisForm10s } from "@/types/model/response/USAFilingType";

export interface Form10ObjectTypeField {
  no: number;
  title: string;
  text: string;
}

export interface ParsedForm10Data {
  [k: string]: string | Form10ObjectTypeField[];
}

type ParseFormType10 = (rawData: AnalysisForm10s) => ParsedForm10Data;

const parseFormType10: ParseFormType10 = (rawData) => {
  const result: ParsedForm10Data = {};

  if (isAllFieldsNull(rawData)) {
    return result;
  }

  if (rawData.businessOverviewKor !== null) {
    result.business = rawData.businessOverviewKor;
  }

  if (rawData.repurchaseKor !== null) {
    result.repurchase = rawData.repurchaseKor;
  }

  if (rawData.riskJSONKor !== null) {
    result.risk = parseJsonFields(rawData.riskJSONKor);
  }

  if (rawData.legalJSONKor !== null) {
    result.legal = parseJsonFields(rawData.legalJSONKor);
  }

  if (hasFinanceFields(rawData)) {
    result.finance = parseFinanceFields(rawData);
  }

  return result;
};

const isAllFieldsNull = (rawData: AnalysisForm10s): boolean => {
  return Object.values(rawData).every((value) => value === null);
};

const parseJsonFields = (
  json: Record<string, string>
): Form10ObjectTypeField[] => {
  return Object.entries(json).map(([title, text], index) => ({
    no: index + 1,
    title,
    text,
  }));
};

const hasFinanceFields = (rawData: AnalysisForm10s): boolean => {
  return (
    rawData.financeEarningKor !== null ||
    rawData.financeLiquidityKor !== null ||
    rawData.financeEquityKor !== null ||
    rawData.financeOthersKor !== null
  );
};

const parseFinanceFields = (
  rawData: AnalysisForm10s
): Form10ObjectTypeField[] => {
  const financeFields = [
    { title: "매출, 영업이익", text: rawData.financeEarningKor },
    { title: "유동성, 현금", text: rawData.financeLiquidityKor },
    { title: "주주 이익", text: rawData.financeEquityKor },
    { title: "기타", text: rawData.financeOthersKor },
  ];

  const result = financeFields
    .filter((field) => field.text !== null)
    .map((field, index) => ({
      no: index + 1,
      title: field.title,
      text: field.text!,
    }));

  return result;
};

export default parseFormType10;
