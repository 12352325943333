import { BrowserRouter, Routes, Route } from "react-router-dom";

import MainPage from "@/components/pages/MainPage";
import DetailPage from "@/components/pages/DetailPage";
import RouteGuard from "@/components/pages/RouteGuard";
import SearchPage from "@/components/pages/SearchPage";
import LinkTest from "@/router/LinkTest";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<RouteGuard />} />
        <Route path="/list" element={<MainPage />} />
        <Route path="/detail" element={<DetailPage />} />
        <Route path="/search" element={<SearchPage />} />
        <Route path="/link-test" element={<LinkTest />} />
        <Route path="*" element={<h1>Not Found</h1>} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
