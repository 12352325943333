import {
  GetSecurityName,
  SecurityNameType,
} from "@/types/model/response/SecurityNameType";
import createGraphqlClient from "@/utils/createGraphqlClient";
import { gql } from "graphql-request";

const query = (selectedCountry: string, searchParam: string) => gql`
  query getSecurityName {
    getSecurityName(
      filter: {
        exchangeType: ${selectedCountry}
        securityCode: "${searchParam}"
      }
    ) {
      securityCode
      securityKorName
    }
  }
`;

const isUndefinedOrNull = (securityNames: GetSecurityName): boolean => {
  return !!(securityNames.securityCode && securityNames.securityKorName);
};

const parseSecurityName = (data: SecurityNameType): GetSecurityName[] => {
  const securityNames = data?.getSecurityName || [];

  return securityNames.filter(isUndefinedOrNull);
};

const getSecurityName = async (
  selectedCountry: string,
  searchParam: string
) => {
  const client = createGraphqlClient();
  return parseSecurityName(
    await client.request(query(selectedCountry, searchParam))
  );
};

export default getSecurityName;
