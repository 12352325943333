import Toggle from "@/components/molecules/list/Toggle";
import styled from "@emotion/styled";
import * as Text from "@/components/atoms/common/Text";

const ToggleAreaContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.75rem;
  padding: 0 1.875rem;

  font-size: 1.125rem;
  line-height: 1.6875rem;

  background: ${({ theme }) => theme.colors.object01};
  border-bottom: 1px solid ${({ theme }) => theme.colors.object03};

  height: 3.75rem;
  color: ${({ theme }) => theme.colors.textColor07};

  transition: all 0.5s ease-in-out;
`;

const ToggleArea = () => {
  return (
    <>
      <ToggleAreaContainer>
        <Text.Spoqa400>내부자 지분변동 포함</Text.Spoqa400>
        <Toggle />
      </ToggleAreaContainer>
    </>
  );
};

export default ToggleArea;
