import dayjs from "dayjs";
import { gql } from "graphql-request";
import { useQuery } from "@tanstack/react-query";

import { useBaseDate, useFilingsStore } from "@/stores/filingsStore";

import createGraphqlClient from "@/utils/createGraphqlClient";
import { T_RESPONSE_HANOI_FILINGS } from "@/types/model/response/HanoiFilingType";
import parseHanoiFiling from "@/utils/parser/parseHanoiFiling";

const query = (baseDate: string, secCode?: string) => gql`
  query HANOI {
    getDisclosure(
      filter: {
        exchangeType: HANOI
        ${secCode ? `securityCode: "${secCode}"` : ""}
        limit: 100
        page: 1
        timeRange: { endDate: "${baseDate}", rangeLength: 1, rangeLengthUnit: Y }
      }
    ) {
      id
      filingDate
      korName
      details {
        ... on Hanoi {
          secCode
          fileLink
          fileType
          categoryId
        }
      }
      analysisDetails {
        summarizeTinyKor
        summarizeLongKor
        topicKor
      }
    }
  }
`;

const useHanoiFilingList = (exchange: string, secCode?: string) => {
  const baseDate = useBaseDate((state) =>
    dayjs(state.baseDate).format("YYYY-MM-DD")
  );

  const setFilings = useFilingsStore((state) => state.setFilings);

  const client = createGraphqlClient();

  const { isLoading, isError, isFetching, isSuccess } = useQuery(
    [exchange, secCode, baseDate],
    () => client.request<T_RESPONSE_HANOI_FILINGS>(query(baseDate, secCode)),
    {
      enabled: true,
      refetchOnWindowFocus: false,
      select: (data) => {
        return parseHanoiFiling(data);
      },
      networkMode: "always",
      // staleTime: 5 * 60 * 1000,
      onSuccess(data) {
        setFilings(data);
      },
    }
  );

  const isReloading = isFetching && isSuccess;

  return { isLoading, isError, isReloading };
};

export default useHanoiFilingList;
