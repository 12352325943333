import { WebviewBridge } from "@/utils/kisutil/WebviewBridge";
import { useLocation } from "react-router-dom";

const LinkTest = () => {

  const urlParam = new URLSearchParams(useLocation().search)
  const usePopup = urlParam.get("use_popup") === "true" ? true : false;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        fontSize: "2rem",
        gap: "1rem",
      }}
    >
      <button
        type="button"
        onClick={() => WebviewBridge.openOrgDocs("https://www.sec.gov/Archives/edgar/data/1478323/000120919123030106/xslF345X04/doc4.xml", "xml", usePopup)}
      >
        edgar(xml)
      </button>
      <button
        type="button"
        onClick={() => WebviewBridge.openOrgDocs("https://www.sec.gov/Archives/edgar/data/1401395/000095017023011561/nept-20230330.htm", "htm", usePopup)}
      >
        edgar(htm)
      </button>
      <button
        type="button"
        onClick={() => WebviewBridge.openOrgDocs("https://www.sec.gov/Archives/edgar/data/1822691/000198032523000004/rolloverjmg.txt", "txt", usePopup)}
      >
        edgar(txt)
      </button>
      <button
        type="button"
        onClick={() => WebviewBridge.openOrgDocs("https://www1.hkexnews.hk/listedco/listconews/sehk/2023/0412/2023041200937.xlsx", "xlsx", usePopup)}
      >
        hkex(xlsx)
      </button>
      <button
        type="button"
        onClick={() => WebviewBridge.openOrgDocs("https://www1.hkexnews.hk/listedco/listconews/sehk/2023/0516/2023051600611.docx", "docx", usePopup)}
      >
        hkex(docx)
      </button>
      <button
        type="button"
        onClick={() => WebviewBridge.openOrgDocs("https://www1.hkexnews.hk/listedco/listconews/sehk/2023/0403/2023040300333.htm", "htm", usePopup)}
      >
        hkex(htm)
      </button>
      <button
        type="button"
        onClick={() => WebviewBridge.openOrgDocs("https://www1.hkexnews.hk/listedco/listconews/sehk/dod/en.html", "html", usePopup)}
      >
        hkex(html)
      </button>
      <button
        type="button"
        onClick={() => WebviewBridge.openOrgDocs("https://www1.hkexnews.hk/listedco/listconews/sehk/2023/0606/2023060601236.pdf", "pdf", usePopup)}
      >
        hkex(pdf)
      </button>
      <button
        type="button"
        onClick={() => WebviewBridge.openOrgDocs("https://disclosure2.edinet-fsa.go.jp/WZEK0040.aspx?S100QWTX", "html", usePopup)}
      >
        japan(html)
      </button>
      <button
        type="button"
        onClick={() => WebviewBridge.openOrgDocs("http://www.sse.com.cn/disclosure/listedinfo/announcement/c/new/2023-04-24/603766_20230424_1K05.pdf", "pdf", usePopup)}
      >
        shanghai(pdf)
      </button>
      <button
        type="button"
        onClick={() => WebviewBridge.openOrgDocs("http://www.szse.cn/disclosure/listed/bulletinDetail/index.html?3fd4c564-5478-44c3-89e3-93c57d551f0a", "html", usePopup)}
      >
        shenzhen(html based pdf)
      </button>
      <button
        type="button"
        onClick={() => WebviewBridge.openOrgDocs("https://www.szse.cn/api/disc/info/download?id=3fd4c564-5478-44c3-89e3-93c57d551f0a", "pdf", usePopup)}
      >
        shenzhen(pdf)
      </button>
      <button
        type="button"
        onClick={() => WebviewBridge.openOrgDocs("https://owa.hnx.vn/ftp/PORTALNEW/HEADER_IMAGES/20230828/SHN_2023.08.23_Extraordinary%20General%20Mandate%202023.docx", "docx", usePopup)}
      >
        vietnam hanoi(docx)
      </button>
      <button
        type="button"
        onClick={() => WebviewBridge.openOrgDocs("https://owa.hnx.vn/ftp/PORTALNEW/HEADER_IMAGES/20230601/20230601_Proprietary%20trading%20results%20by%20stock%20Listed%20market.pdf", "pdf", usePopup)}
      >
        vietnam hanoi(pdf)
      </button>
      <button
        type="button"
        onClick={() => WebviewBridge.openOrgDocs("https://owa.hnx.vn/ftp/PORTALNEW/HEADER_IMAGES/20230808/PTI_Financial%20Statement.%20Quarter%202.%202023%20-%20consolidated.xls", "xls", usePopup)}
      >
        vietnam hanoi(xls)
      </button>
      <button
        type="button"
        onClick={() => WebviewBridge.openOrgDocs("https://owa.hnx.vn/ftp/PORTALNEW/HEADER_IMAGES/20230817/PRE_%20Reviewed%20semi-annual%20financial%20statement%202023.xlsx", "xlsx", usePopup)}
      >
        vietnam hanoi(xlsx)
      </button>
      <button
        type="button"
        onClick={() => WebviewBridge.openOrgDocs("https://owa.hnx.vn/ftp/ftpserver/2023/7_W5/VSA_2023.7.24_aded084_financial_statements.zip", "zip", usePopup)}
      >
        vietnam hanoi(zip)
      </button>
      <button
        type="button"
        onClick={() => WebviewBridge.openOrgDocs("https://www.hsx.vn/Modules/Cms/Web/DownloadFile/55076311-5278-485a-b8af-f670b8eb5e42?ridx=165928901", "pdf", usePopup)}
      >
        vietnam hochimin(pdf)
      </button>
    </div>
  );
};

export default LinkTest;
