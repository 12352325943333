import { EXCHANGE } from "@/types/EXCHANGE";
import { T_RESPONSE_HANOI_FILINGS } from "@/types/model/response/HanoiFilingType";
import { MODEL_VIEW_FILINGS_ITEM } from "@/types/model/view/ParsedFilingType";
import { getKoreanValue } from "@/utils/parser/parseFilingResponse";

type T_PARSE_HANOI_FILING = (
  data: T_RESPONSE_HANOI_FILINGS
) => MODEL_VIEW_FILINGS_ITEM[];

const parseHanoiFiling: T_PARSE_HANOI_FILING = (data) => {
  if (!data.getDisclosure) return [];
  return data.getDisclosure.map((item) => {
    return {
      id: item.id,
      dataDate: item.filingDate,
      korName: item.korName || "",
      exchangeLabel: "HNX",
      form: getKoreanValue(item.details.categoryId),
      topic: item.analysisDetails.topicKor,
      summaryTiny: item.analysisDetails.summarizeTinyKor,
      summaryLong: item.analysisDetails.summarizeLongKor,
      originURL: item.details.fileLink ? item.details.fileLink[0] : "",
      fileType: item.details.fileType ? item.details.fileType[0] : "",
      exchange: EXCHANGE.HANOI,
      secCode: item.details.secCode || "",
    };
  });
};

export default parseHanoiFiling;
