import { create } from "zustand";

interface ToggleStore {
  isToggled: boolean;
  toggle: () => void;
  setIsToggle: (isToggled: boolean) => void;
}

const useToggleStore = create<ToggleStore>((set) => ({
  isToggled: false,
  toggle: () => set((state) => ({ isToggled: !state.isToggled })),
  setIsToggle: (isToggled: boolean) => set({ isToggled }),
}));

export default useToggleStore;
