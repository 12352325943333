import styled from "@emotion/styled";

import { TagText } from "@/components/atoms/list/TagText";

interface TagProps {
  children: string;
}

const TagContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Tag: React.FC<TagProps> = ({ children }) => {
  return (
    <TagContainer>
      <TagText>{children}</TagText>
    </TagContainer>
  );
};

export default Tag;
