import styled from "@emotion/styled";

import * as Text from "@/components/atoms/common/Text";

export const Summary = styled(Text.Spoqa400)`
  font-size: 1.3125rem;
  line-height: 1.875rem;

  color: ${({ theme }) => theme.colors.object09};
`;
