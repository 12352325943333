import { Summary } from "@/components/atoms/list/Summary";

import { MODEL_VIEW_FILINGS_ITEM } from "@/types/model/view/ParsedFilingType";

interface FilingItemBodyProps {
  item: MODEL_VIEW_FILINGS_ITEM;
}

const FilingItemBody: React.FC<FilingItemBodyProps> = ({ item }) => {
  return <Summary>{item.summaryTiny}</Summary>;
};

export default FilingItemBody;
