export interface CustomTheme {
  colors: {
    primary: string;
    accent01: string;
    accent02: string;
    mtsTextColor01: string;
    mtsTextColor02: string;
    textColor01: string;
    textColor02: string;
    textColor03: string;
    textColor04: string;
    textColor05: string;
    textColor06: string;
    textColor07: string;
    textColor08: string;
    textColor09: string;
    textColor10: string;
    textColor11: string;
    object01: string;
    object02: string;
    object03: string;
    object04: string;
    object05: string;
    object06: string;
    object07: string;
    object08: string;
    object09: string;
    primary100: string;
    primary70: string;
    primary50: string;
    secondary: string;
    gray100: string;
    gray70: string;
    gray50: string;
    gray30: string;
    blue: string;
    white: string;
    black: string;
    realBlack: string;
    black100: string;
    black70: string;
    black50: string;
    black30: string;
    white100: string;
    white70: string;
    white50: string;
    white30: string;
    white10: string;
  };
}

export const lightTheme: CustomTheme = {
  colors: {
    primary: "#000000",
    accent01: "#EC5F5F",
    accent02: "#50BEC6",
    mtsTextColor01: "#EA1724",
    mtsTextColor02: "#235BED",
    textColor01: "#CFCFCF",
    textColor02: "#AAA",
    textColor03: "#909090",
    textColor04: "#737373",
    textColor05: "#4A4A4A",
    textColor06: "#0B0B0B",
    textColor07: "#8C8E94",
    textColor08: "#7B7B7B",
    textColor09: "#666666",
    textColor10: "#333333",
    textColor11: "#FFFFFF",
    object01: "#F9F9FA",
    object02: "#F2F3F4",
    object03: "#E3E4E5",
    object04: "#CBCDCF",
    object05: "#A4A6AA",
    object06: "#75777B",
    object07: "#44464A",
    object08: "#33353C",
    object09: "#1C1D1F",
    primary100: "#002C2C",
    primary70: "#4D6B6B",
    primary50: "#84C13D",
    secondary: "#B1F54D",
    gray100: "#DBDBDB",
    gray70: "#C5C5C5",
    gray50: "#F3F3F3",
    gray30: "#BDBDBD",
    blue: "#6FADFF",
    white: "#FFFFFF",
    black: "#333333",
    realBlack: "#000000",
    black100: "#000000",
    black70: "#333333",
    black50: "#666666",
    black30: "#999999",
    white100: "#FFFFFF",
    white70: "#F9F9F9",
    white50: "#F7F7F7",
    white30: "#F0F0F0",
    white10: "#E0E0E0",
  },
};

export const darkTheme: CustomTheme = {
  colors: {
    primary: "#000000",
    accent01: "#EC5F5F",
    accent02: "#50BEC6",
    mtsTextColor01: "#EA1724",
    mtsTextColor02: "#235BED",
    textColor01: "#CFCFCF",
    textColor02: "#AAA",
    textColor03: "#909090",
    textColor04: "#737373",
    textColor05: "#4A4A4A",
    textColor06: "#0B0B0B",
    textColor07: "#8C8E94",
    textColor08: "#7B7B7B",
    textColor09: "#666666",
    textColor10: "#333333",
    textColor11: "#FFFFFF",
    object01: "#F9F9FA",
    object02: "#F2F3F4",
    object03: "#E3E4E5",
    object04: "#CBCDCF",
    object05: "#A4A6AA",
    object06: "#75777B",
    object07: "#44464A",
    object08: "#33353C",
    object09: "#1C1D1F",
    primary100: "#002C2C",
    primary70: "#4D6B6B",
    primary50: "#84C13D",
    secondary: "#B1F54D",
    gray100: "#DBDBDB",
    gray70: "#C5C5C5",
    gray50: "#F3F3F3",
    gray30: "#BDBDBD",
    blue: "#6FADFF",
    white: "#FFFFFF",
    black: "#333333",
    realBlack: "#000000",
    black100: "#000000",
    black70: "#333333",
    black50: "#666666",
    black30: "#999999",
    white100: "#FFFFFF",
    white70: "#F9F9F9",
    white50: "#F7F7F7",
    white30: "#F0F0F0",
    white10: "#E0E0E0",
  },
};
