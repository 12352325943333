import styled from "@emotion/styled";

const WarningContainer = styled.div`
  @media (max-width: 480px) {
    scale: calc(480 / 540);
  }

  @media (max-width: 430px) {
    scale: calc(430 / 540);
  }

  @media (max-width: 428px) {
    scale: calc(428 / 540);
  }

  @media (max-width: 414px) {
    scale: calc(414 / 540);
  }

  @media (max-width: 390px) {
    scale: calc(390 / 540);
  }

  @media (max-width: 375px) {
    scale: calc(375 / 540);
  }

  @media (max-width: 360px) {
    scale: calc(360 / 540);
  }

  @media (max-width: 320px) {
    scale: calc(320 / 540);
  }

  @media (max-width: 280px) {
    scale: calc(280 / 540);
  }
`;

const WarningIcon = () => {
  return (
    <WarningContainer>
      <svg
        width="84"
        height="84"
        viewBox="0 0 84 84"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_169_3435)">
          <path
            d="M42 0C18.804 0 0 18.804 0 42C0 65.196 18.804 84 42 84C65.196 84 84 65.196 84 42C84 18.804 65.196 0 42 0Z"
            fill="#F2F3F4"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M41.9675 27.125C40.2654 27.125 38.9043 28.5395 38.9697 30.2403L39.5466 45.2393C39.5973 46.5575 40.6806 47.6 41.9999 47.6C43.3191 47.6 44.4025 46.5575 44.4532 45.2393L45.03 30.2403C45.0954 28.5395 43.7343 27.125 42.0323 27.125H41.9675ZM41.9999 56.8648C43.7396 56.8648 45.1499 55.4545 45.1499 53.7148C45.1499 51.9751 43.7396 50.5648 41.9999 50.5648C40.2602 50.5648 38.8499 51.9751 38.8499 53.7148C38.8499 55.4545 40.2602 56.8648 41.9999 56.8648Z"
            fill="#CBCDCF"
          />
        </g>
        <defs>
          <clipPath id="clip0_169_3435">
            <rect width="84" height="84" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </WarningContainer>
  );
};

export default WarningIcon;
