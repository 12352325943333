import { create } from "zustand";

type SearchStore = {
  searchKeyword: string;
  selectedCountry: string;
  currentItem: string;
  setSearchKeyword: (search: string) => void;
  setSelectedCountry: (country: string) => void;
  setCurrentItem: (item: string) => void;
};

const useSearchStore = create<SearchStore>((set) => ({
  searchKeyword: "",
  selectedCountry: "미국",
  currentItem: "",
  setSearchKeyword: (search: string) => set({ searchKeyword: search }),
  setSelectedCountry: (country: string) => set({ selectedCountry: country }),
  setCurrentItem: (item: string) => set({ currentItem: item }),
}));

export default useSearchStore;
