import { EXCHANGE } from "@/types/EXCHANGE";
import { T_RESPONSE_HOCHIMINH_FILINGS } from "@/types/model/response/HochiminhFilingType";
import { MODEL_VIEW_FILINGS_ITEM } from "@/types/model/view/ParsedFilingType";
import { getKoreanValue } from "@/utils/parser/parseFilingResponse";

type T_PARSE_HOCHIMINH_FILING = (
  data: T_RESPONSE_HOCHIMINH_FILINGS
) => MODEL_VIEW_FILINGS_ITEM[];

const parseHochiminhFiling: T_PARSE_HOCHIMINH_FILING = (data) => {
  if (!data.getDisclosure) return [];
  return data.getDisclosure.map((item) => {
    return {
      id: item.id,
      dataDate: item.filingDate,
      korName: item.korName || "",
      exchangeLabel: "HSX",
      form: "",
      topic: item.analysisDetails.topicKor,
      summaryTiny: item.analysisDetails.summarizeTinyKor,
      summaryLong: item.analysisDetails.summarizeLongKor,
      originURL: item.details.fileLink ? item.details.fileLink[0] : "",
      fileType: "",
      exchange: EXCHANGE.HOCHIMINH,
      secCode: item.details.secCode || "",
    };
  });
};

export default parseHochiminhFiling;
